import styled, { keyframes } from 'styled-components'

export const Wrapper = styled.section`
  margin: 0 auto 5rem auto;
  width: 90vw;
  position: relative;
`

export const SearchContainer = styled.div`
  padding: 10rem 5rem 10rem 5rem;
  background-color: #0f0f0f;
  & > h2 {
    color: #fff;
    font-weight: lighter;
    text-align: center;
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 1024px) {
    padding: 10rem 2rem;
  }
`

export const Search = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  margin: 0 auto;

  @media screen and (max-width: 1024px) {
    row-gap: 1rem;
  }

  & > section {
    width: 100%;
    display: flex;
    column-gap: 1rem;
    align-items: center;

    & > div {
      display: block;
      flex: 1;

      & label {
        color: #fff;
      }

      & > div {
        display: flex;
        flex-direction: row;
        align-items: center;

        & input,
        & select {
          flex: 1;
          margin-top: 0.3rem;
          padding: 0.5rem 1rem;
          border-radius: 1.5rem;
          border: 0.5px solid #fff;
        }

        & > button {
          flex: 1;
          color: #fff;
          cursor: pointer;
          font-weight: bolder;
          padding: 0.5rem 1rem;
          border: 0.5px solid #c0a36a;
          background-color: #c0a36a;
          transition: all 0.3s linear;
          position: relative;
          top: 0.7rem;

          &:first-of-type {
            border-radius: 1.5rem 0 0 1.5rem;
          }

          &:last-of-type {
            border-radius: 0 1.5rem 1.5rem 0;
          }

          &:hover {
            background-color: transparent;
          }
        }

        & > p {
          flex: 0;
          margin: 0 1rem;
          font-size: 1.3rem;
        }
      }
    }

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      row-gap: 1rem;

      & > div {
        width: 100%;

        & > div {
          width: 100%;

          & > * {
            width: inherit;
          }
        }
      }
    }
  }
`

export const PlaceHolder = styled.p`
  height: 25vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const spinnerAnimation = keyframes`
    100% {
        transform:rotate(360deg)
    }
`

export const Spinner = styled.path`
  transform-origin: center;
  fill: #c0a36a;
  animation: ${spinnerAnimation} 0.6s linear infinite;
`

export const SpinnerWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PaginateSpinnerWrapper = styled.div`
  width: 100dvw;
  height: 30dvh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ItemWrapper = styled.div`
  padding: 0.5rem;
  width: 33%;
  display: flex;
  flex: none;
  grid-auto-rows: 1fr;
  align-content: stretch;
  box-sizing: border-box;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`

export const Offers = styled.section`
  padding: 0 5rem;

  & > div {
    display: grid;
    row-gap: 1rem;
    position: relative;
    top: -5rem;
    grid-template-columns: repeat(3, 1fr);

    @media screen and (max-width: 1024px) {
      grid-template-columns: 1fr;
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 0 1rem;
  }

  & > section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
    gap: 2px;

    & > button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2.5rem;
      cursor: pointer;
      border: none;
      outline: none;
      height: 31px;
      width: 31px;
      background-color: transparent;
      transition: all 0.3s ease-in-out;

      &:hover {
        & path {
          stroke: #c0a36a;
        }
      }
    }

    .border {
      height: 25px;
      width: 25px;
      font-size: 1rem;
      border-radius: 50%;
      border: 2px solid #0f0f0f;
    }

    .active {
      color: #c0a36a;
      border-color: #c0a36a;
    }
  }
`
