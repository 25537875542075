import { Wrapper, Spinner as StyledSpinner } from './spinnerElements'

/**
 * Represents a spinner component.
 * @returns {JSX.Element} Spinner SVG wrapped in a styled container.
 */
const Spinner = () => {
  return (
    <Wrapper>
      <svg
        width='100'
        height='100'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
      >
        <StyledSpinner d='M2,12A11.2,11.2,0,0,1,13,1.05C12.67,1,12.34,1,12,1a11,11,0,0,0,0,22c.34,0,.67,0,1-.05C6,23,2,17.74,2,12Z' />
      </svg>
    </Wrapper>
  )
}

export default Spinner
