import React, { useState, useRef } from 'react'
import {
  SubHeader2,
  ContactCol,
  Row,
  Coc,
  ContactUs,
  Location,
  Team,
  Coc1,
  Text,
  Text1,
} from './contactElements'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'

import API from '../../api'
import { useForm, Controller } from 'react-hook-form'

import AgentCard from '../../lib/AgentCard'

const ContactForm = () => {
  const { t } = useTranslation()

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [setShowSuccessMessage] = useState(false)

  const { control, register, handleSubmit } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
    },
  })

  const onSubmit = (data) => {
    data.category = 'contact request'
    API.sendContactRequest(data).then((res) => {
      setIsSubmitted(true)
      setShowSuccessMessage(true)
    })
  }

  return (
    <motion.form
      onSubmit={handleSubmit(onSubmit)}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
    >
      {isSubmitted && <Text>Twoja wiadomość została wysłana pomyślnie.</Text>}

      <Controller
        control={control}
        name={'name'}
        render={({}) => (
          <input
            type='text'
            name='name'
            placeholder={t('contact.name')}
            {...register('name')}
          />
        )}
      />
      <Controller
        control={control}
        name={'email'}
        render={({}) => (
          <input
            type='email'
            name='email'
            placeholder={t('contact.email')}
            {...register('email')}
          />
        )}
      />
      <Controller
        control={control}
        name={'phone'}
        render={({}) => (
          <input
            type='text'
            name='phone'
            placeholder={t('contact.phone')}
            {...register('phone')}
          />
        )}
      />
      <Controller
        control={control}
        name={'subject'}
        render={({}) => (
          <input
            type='text'
            name='subject'
            placeholder={t('contact.subject')}
            {...register('subject')}
          />
        )}
      />
      <Controller
        control={control}
        name={'message'}
        render={({}) => (
          <textarea
            rows='8'
            name='message'
            placeholder={t('contact.message')}
            {...register('message')}
          />
        )}
      />
      <button type='submit'>{t('contact.send')}</button>
    </motion.form>
  )
}

const Contact = () => {
  const { t } = useTranslation()

  return (
    <>
      <SubHeader2>
        <motion.h1
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          {t('contact.maintitle')}
        </motion.h1>
      </SubHeader2>
      <Text1>
        <motion.h1
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          {t('contact.title')}
        </motion.h1>
        <hr />
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 1 }}
        >
          {t('contact.subtitle1')}
          <br />
          {t('contact.subtitle2')}
        </motion.p>
      </Text1>
      <ContactUs>
        <Row>
          <ContactCol>
            <Coc>
              <span>
                <h5>Skontaktuj się z nami</h5>
                <p>+48 501 858 805</p>
              </span>
            </Coc>
            <Coc>
              <span>
                <h5>{t('contact.open_hours')}</h5>
                <p>{t('contact.hours')}</p>
              </span>
            </Coc>
            <Coc>
              <span>
                <h5>Cystersów 13A/1</h5>
                <p>31-553 Kraków, PL</p>
              </span>
            </Coc>
            <Coc>
              <span>
                <h5>info@aprest.pl</h5>
                <p>{t('contact.send_us_message')}</p>
              </span>
            </Coc>
            <Coc>
              <span>
                <a href='/contact/property'>{t('contact.register_property')}</a>
              </span>
            </Coc>
            <Coc>
              <span>
                <a href='/contact/search'>{t('contact.register_search')}</a>
              </span>
            </Coc>
            <Coc1>
              <span>
                <a href='/contact/credit'>{t('contact.ask_mortgage')}</a>
              </span>
            </Coc1>
          </ContactCol>
          <ContactCol>
            <ContactForm></ContactForm>
          </ContactCol>
        </Row>
      </ContactUs>
      <Team>
        <h1>{t('contact.our_team')}</h1>
        <section>
          {['25081', '25082', '25702', '25785'].map((agentId) => (
            <AgentCard agentId={agentId} />
          ))}
        </section>
      </Team>
      <Location>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20485.564230660704!2d19.95150875517889!3d50.073263477419225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47165bb621a118ef%3A0xc3b14b3deee43f06!2sAPREST%20SP.%20Z.O.O!5e0!3m2!1spl!2spl!4v1695658440434!5m2!1spl!2spl'
          width='600'
          height='450'
          style={{ border: 0 }}
          allowFullScreen=''
          loading='lazy'
          referrerPolicy='no-referrer-when-downgrade'
        ></iframe>
      </Location>
    </>
  )
}

export default Contact
