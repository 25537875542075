import styled from 'styled-components'
import img16 from '../../assets/images/img16.jpg';

export const SubHeader2 = styled.div`
    min-height: 50vh;
    width: 100%;
    background-image: linear-gradient(rgba(4,9,30,0.7),rgba(4,9,30,0.7)), url(${img16});
    background-position: center;
    position:  relative;
    background-size: cover;
    text-align: center;
    color: #fff;
    

    & h1{
        line-height: 14.5;
        color: #fff;
        font-weight: 400;
        
    }


    & h4{
        color: #fff;
        text-align: center;
        position: relative;
        top: -150px;
        font-weight: 400;
        font-size: 1.2rem;
    }
`


export const ContactUs = styled.div`
    width: 80%;
    margin: auto;
`

export const Row = styled.div`
     margin-top: 5%;
     display: flex;
     justify-content: space-between;
` 

export const ContactCol = styled.div`
    flex-basis: 48%;
    margin-bottom: 30px;

    & select{
        width: 100%;
        padding: 15px;
        margin-bottom: 17px;
        outline: none;
        border: 1px solid #ccc;
    }
    
    & input{
        width: 100%;
        padding: 15px;
        margin-bottom: 17px;
        outline: none;
        border: 1px solid #ccc;
    /* position: relative;
	right: -600px;
    top: -100px; */
    }

    & textarea{
        width: 100%;
        padding: 15px;
        margin-bottom: 17px;
        outline: none;
        border: 1px solid #ccc;
    /* position: relative;
    right: -600px;
    top: -100px; */
    }


    & button{
        display: inline-block;
        text-decoration: none;
        color: #fff;
        border: 1px solid #c0a36a;
        padding: 12px 34px;
        font-size: 13px;
        background: #c0a36a;
        position: relative;
        cursor: pointer;
        /* right: -600px;
        top: -100px; */
        transition: all .3s ease-in-out;
      
    }

    & button:hover{
        border: 1px solid #c0a36a;
        background: #fff;
        cursor: pointer;
        color: #c0a36a;
    }

`


export const Coc = styled.div`
     display: flex;
     align-items: center;
     margin-bottom: 40px;

     & input{
         width: 100%;
         padding: 15px;
         margin-bottom: 17px;
         outline: none;
         border: 1px solid #ccc;
     }


     & p{
         padding: 0;
     }

     & h5{
         font-size: 20px;
         margin-bottom: 5px;
         color: #555;
         font-weight: 400;
     }

     & a{
        display: inline-block;
        text-decoration: none;
        color: #fff;
        border: 1px solid #c0a36a;
        padding: 12px 34px;
        font-size: 13px;
        background: #c0a36a;
        position: relative;
        cursor: pointer;
        /* right: -600px;
        top: -100px; */
        transition: all .3s ease-in-out;
        min-width: 187px;
        text-align:center;
     }

     & a:hover{
        border: 1px solid #c0a36a;
        background: #fff;
        cursor: pointer;
        color: #c0a36a;
    }
`

 export const Location = styled.div`
     width: 100vw; 
     margin: auto; 
     padding:  80px 0; 


        & iframe{
             width: 100%; 
            height: 450px; 
        }

        
     `

export const TextForm = styled.p`
        
background-color: rgba(13, 157, 75, 0.94);
color: white;
padding: 10px;
margin-bottom: 10px;
text-align: center;
        
` 



