import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Container,
  FooterSection,
  Box,
  Logo,
  InputSan,
  CopyRight,
  Lista,
  InstagramIcon,
  FacebookIcon,
  YoutubeIcon,
  Designed,
} from './footerElements'
import Logo_WHITE from '../../assets/images/Logo_WHITE.png'
import Cookies from 'js-cookie'

const Footer = () => {
  const currentYear = new Date().getFullYear()
  const { t, i18n } = useTranslation()
  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language)

    Cookies.set('lang', language, { expires: 365 })
  }

  return (
    <>
      <FooterSection>
        <Container>
          <Box>
            <Logo>
              <img
                style={{
                  display: 'block',
                  height: '80px',
                  width: 'auto',
                  position: 'relative',
                  right: '13px',
                }}
                src={Logo_WHITE}
                alt=''
              />
              <h2>{t('footer.title')}</h2>
              <p>{t('footer.subtitle')}</p>
              <InputSan>
                <a href='/contact'>{t('footer.contact_us')}</a>
              </InputSan>
            </Logo>
          </Box>
          <Lista>
            <ul>
              <h3>{t('footer.info')}</h3>
              <li>NIP: 6751785588</li>
              <li>31-553 Kraków</li>
              <li>Cystersów 13A/1 </li>
            </ul>
          </Lista>
          <Lista>
            <ul>
              <h3>{t('footer.contact')}</h3>
              <li>
                <a href={'tel:48501858805'}>+48 501 858 805</a>
              </li>
              <li>
                <a href={'mailto:info@aprest.pl'}>info@aprest.pl</a>
              </li>
            </ul>
          </Lista>
        </Container>
        <Container>
          <Lista>
            <ol>
              <h3>Social Media</h3>
              <li>
                <a
                  target={'_blank'}
                  href={'https://www.facebook.com/profile.php?id=61551918651411'}
                >
                  <FacebookIcon />
                </a>
              </li>
              <li>
                <a
                  target={'_blank'}
                  href={'https://instagram.com/aprest_realestate?igshid=OGQ5ZDc2ODk2ZA=='}
                >
                  <InstagramIcon />
                </a>
              </li>
              <li>
                <a
                  target={'_blank'}
                  href={'https://www.youtube.com/channel/UCbmPLHwUikpsjNIKcHXjGLQ'}
                >
                  <YoutubeIcon />
                </a>
              </li>
            </ol>
          </Lista>
          <Lista>
            <ol>
              <h3>{t('footer.language')}</h3>
              <li
                onClick={() => handleLanguageChange('pl')}
                style={{ marginRight: '1rem', cursor: 'pointer' }}
              >
                Polski
              </li>
              <li
                onClick={() => handleLanguageChange('en')}
                style={{ cursor: 'pointer' }}
              >
                English
              </li>
            </ol>
          </Lista>
        </Container>
        <a
          href='http://virgo.galactica.pl/'
          target={'_blank'}
          title='oprogramowanie dla biur nieruchomości'
        >
          POWERED BY VIRGO API
        </a>
      </FooterSection>

      <CopyRight>
        <span>Copyright © {currentYear} Aprest Nieruchomości</span>
      </CopyRight>
      <Designed>
        <span>
          Designed by {''}
          <a
            href={'https://onitsoft.com'}
            target={'_blank'}
          >
            <b>OnitSoft</b>
          </a>
        </span>
      </Designed>
    </>
  )
}

export default Footer
