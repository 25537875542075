import styled from 'styled-components'

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  box-shadow: ${(props) => (!props.isScrolled ? 'none' : '0 5px 15px 0 rgba(0, 0, 0, .10)')};
  background-color: ${(props) =>
    !props.isScrolled ? 'rgba(255, 255, 255, 0.8)' : 'rgba(255,255,255,.8)'};
  position: fixed;
  top: 0;
  min-height: 110px;
  width: 100vw;
  z-index: 1000;
  transition: all 0.2s linear;
`

export const Logo = styled.a`
  color: rgb(69, 69, 69);
  cursor: pointer;
  z-index: 1000;
  left: -10px;
  position: fixed;

  & > img {
    height: 100px;
    width: auto;
    filter: drop-shadow(2px 2px 2px rgba(32, 30, 29, 0.2));
    position: relative;
    left: 30px;
  }

  &:hover {
    color: #c0a36a;
  }
`

export const NavMenu = styled.ul`
  list-style-type: none;

  & li {
    display: inline-block;
    cursor: pointer;
  }

  & a {
    display: block;
    padding: 10px 20px;
    margin: 30px 0;
    text-decoration: none;
    cursor: pointer;
    color: ${(props) => (!props.isScrolled ? 'rgb (69, 69, 69)' : 'rgb (69, 69, 69)')};
    transition: all 0.2s linear;
    margin: 0 0.5rem;
    text-transform: uppercase;

    &:hover {
      color: #c0a36a;
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
`

export const SignButton = styled.li`
  display: inline-block;
  border-radius: 0.3rem;
  font-size: auto;
  font-weight: 550;
  border: none;
  cursor: pointer;

  & > a:hover {
    background-color: #c0a36a;
    border-radius: 25px;
    transition: background-color 0.2s ease-in-out;
    color: #fff;
  }
`

export const Img = styled.img`
  display: flex;
  align-items: center;
  width: 10;
  height: 120px;
`

export const BurgerMenu = styled.div`
  display: none;
  width: 50px;
  height: 32px;
  cursor: pointer;
  z-index: 1000;

  & > span {
    display: block;
    width: 100%;
    height: 2px;
    position: relative;
    background-color: #c0a36a;
    transition: all 0.3s linear;

    &:first-of-type {
      transform: ${(props) => (props.isOpen ? 'translateY(15px) rotate(45deg)' : 'rotate(0deg)')};
    }

    &:nth-child(2) {
      top: 13px;
      opacity: ${(props) => (props.isOpen ? 0 : 1)};
    }

    &:last-of-type {
      top: 26px;
      transform: ${(props) => (props.isOpen ? 'translateY(-14px) rotate(-45deg)' : 'rotate(0deg)')};
    }
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`

export const Drawer = styled.div`
  height: calc(100vh);
  width: 100vw;
  position: absolute;
  top: ${(props) => (props.isOpen ? '0' : '-100vh')};
  left: 0;
  transition: all 0.3s linear;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(30px);

  @supports not (backdrop-filter: blur(0)) {
    backdrop-filter: none;
    background-color: #fff;
  }

  & > a {
    color: #c0a36a;
    letter-spacing: 0.06rem;
    font-size: 2rem;
    text-decoration: none;
    margin-bottom: 2rem;
  }
`
