export const MenuItems = [
  {
    url: '/',
    cName: 'nav-links',
    dict_key: 'menu.home',
  },
  {
    url: '/#about-us',
    cName: 'nav-links',
    dict_key: 'menu.about',
  },
  {
    url: '/contact',
    cName: 'nav-links',
    dict_key: 'menu.contact',
  },
  {
    url: '/contact/credit',
    cName: 'nav-links',
    dict_key: 'menu.credit',
  },
  {
    url: '/career',
    cName: 'nav-links',
    dict_key: 'menu.career',
  },
  {
    url: '/offer',
    cName: 'nav-links',
    dict_key: 'menu.real_estate',
  },
]
