import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SubHeader2, ContactCol, Row, Coc, ContactUs, Location, TextForm } from './propertyElements'

import API from '../../api'
import { useForm, Controller } from 'react-hook-form'

const ContactForm = () => {
  const { t } = useTranslation()

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [setShowSuccessMessage] = useState(false)

  const { control, register, handleSubmit } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
    },
  })

  const onSubmit = (data) => {
    data.category = 'contact request'
    API.sendContactRequest(data).then((res) => {
      console.log(res)
      setIsSubmitted(true)
      setShowSuccessMessage(true)
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isSubmitted && <TextForm>Twoja wiadomość została wysłana pomyślnie.</TextForm>}

      <Controller
        control={control}
        name={'name'}
        render={({}) => (
          <input
            type='text'
            name='name'
            placeholder={t('contact.name')}
            {...register('name')}
          />
        )}
      />
      <Controller
        control={control}
        name={'email'}
        render={({}) => (
          <input
            type='email'
            name='email'
            placeholder={t('contact.email')}
            {...register('email')}
          />
        )}
      />
      <Controller
        control={control}
        name={'phone'}
        render={({}) => (
          <input
            type='text'
            name='phone'
            placeholder={t('contact.phone')}
            {...register('phone')}
          />
        )}
      />
      <Controller
        control={control}
        name={'subject'}
        render={({}) => (
          <input
            type='text'
            name='subject'
            placeholder={t('contact.subject')}
            {...register('subject')}
          />
        )}
      />
      <Controller
        control={control}
        name={'message'}
        render={({}) => (
          <textarea
            rows='8'
            name='message'
            placeholder={t('contact.message')}
            {...register('message')}
          />
        )}
      />
      <button type='submit'>{t('contact.send')}</button>
    </form>
  )
}

const Contact = () => {
  const { t } = useTranslation()
  return (
    <>
      <SubHeader2>
        <h1>{t('property.title')}</h1>
        <h4>
          {t('property.subtitle')} {t('property.subtitle2')}
        </h4>
      </SubHeader2>

      <ContactUs>
        <Row>
          <ContactCol>
            <Coc>
              <span>
                <h5>+48 501 858 805</h5>
                <p>{t('contact.open_hours')}, 8-16</p>
              </span>
            </Coc>
            <Coc>
              <span>
                <h5>Cystersów 13A/1</h5>
                <p>31-553 Kraków, PL</p>
              </span>
            </Coc>
            <Coc>
              <span>
                <h5>info@aprest.pl</h5>
                <p>{t('contact.send_us_message')}</p>
              </span>
            </Coc>
            <Coc>
              <span>
                <a href='/contact/search'>{t('contact.register_search')}</a>
              </span>
            </Coc>
            <Coc>
              <span>
                <a href='/contact/credit'>{t('contact.ask_mortgage')}</a>
              </span>
            </Coc>
          </ContactCol>
          <ContactCol>
            <ContactForm></ContactForm>
          </ContactCol>
        </Row>
      </ContactUs>

      <Location>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20485.564230660704!2d19.95150875517889!3d50.073263477419225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47165bb621a118ef%3A0xc3b14b3deee43f06!2sAPREST%20SP.%20Z.O.O!5e0!3m2!1spl!2spl!4v1695658440434!5m2!1spl!2spl'
          width='600'
          height='450'
          style={{ border: 0 }}
          allowFullScreen=''
          loading='lazy'
          referrerPolicy='no-referrer-when-downgrade'
        ></iframe>
      </Location>
    </>
  )
}
export default Contact
