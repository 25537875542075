import * as React from 'react'
import { useTranslation } from 'react-i18next'
import API from '../../api'
import Map from './map'
import {
  HeaderWrapper,
  Header,
  Title,
  TitleBar,
  Wrapper,
  BedIcon,
  Content,
  Agent,
  LeftIcon,
  RightIcon,
  Price,
  SquareIcon,
  Photos,
  SpinnerWrapper,
  Spinner,
  MapWrapper,
  LoadingWrapper,
  ElevatorIcon,
  SolidCameraIcon,
  AcIcon,
  RouterIcon,
  PhotoPop,
  PopLeftIcon,
  PopRightIcon,
  ClosePopIcon,
} from './estateElements'

import AgentCard from '../../lib/AgentCard'

const Estate = () => {
  const { t, i18n } = useTranslation()
  const pathname = window.location.pathname
  let temp = pathname.split('/')
  const path = temp[temp.length - 1]

  const [isSuccess, setIsSuccess] = React.useState(false)
  const [offer, setOffer] = React.useState(null)

  React.useEffect(() => {
    API.getOffer(path).then((res) => {
      setOffer(res.data.response[0])
      setIsSuccess(true)
    })
  }, [])

  const createMarkup = (htmlContent) => {
    return { __html: htmlContent }
  }

  const carouselRef = React.useRef(null)

  let counter = 0
  const scroll = (direction) => {
    if (carouselRef.current) {
      counter += direction
      if (counter === offer.photos.length - 1) {
        carouselRef.current.scrollBy({
          left: -1 * (offer.photos.length - 1) * document.getElementById('slider').offsetWidth,
          behavior: 'smooth',
        })
        counter = 0
      } else {
        carouselRef.current.scrollBy({
          left: direction * document.getElementById('slider').offsetWidth,
          behavior: 'smooth',
        })
      }
    }
  }

  const [imageLoaded, setImageLoaded] = React.useState(false)

  const handleImageLoad = () => {
    setImageLoaded(true)
  }

  let iterations = 0

  let intervalId
  React.useEffect(() => {
    if (offer && offer.photos && offer.photos.length) {
      const maxIterations = offer.photos.length
      intervalId = setInterval(() => {
        scroll(1)
        iterations++

        if (iterations >= maxIterations) {
          clearInterval(intervalId)
        }
      }, 14000)
    }
  }, [imageLoaded])

  const scrollClick = (direction) => {
    scroll(direction)

    clearInterval(intervalId)
  }

  const [options, setOptions] = React.useState([])
  React.useEffect(() => {
    if (offer?.additional_options) {
      setOptions(offer.additional_options.split(';'))
    }
  }, [offer])

  const [isPopActive, setIsPopActive] = React.useState(false)
  const [currentImageIndex, setCurrentImageIndex] = React.useState(0)

  return isSuccess ? (
    <Wrapper>
      <HeaderWrapper>
        <Header
          ref={carouselRef}
          id={'slider'}
        >
          {imageLoaded ? null : (
            <div>
              <div>
                <SpinnerWrapper>
                  <svg
                    width='100'
                    height='100'
                    viewBox='0 0 24 24'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <Spinner d='M2,12A11.2,11.2,0,0,1,13,1.05C12.67,1,12.34,1,12,1a11,11,0,0,0,0,22c.34,0,.67,0,1-.05C6,23,2,17.74,2,12Z' />
                  </svg>
                </SpinnerWrapper>
              </div>
            </div>
          )}
          <div>
            {offer.photos.map((photo, index) => (
              <>
                <div
                  onClick={() => setIsPopActive(true)}
                  style={{
                    backgroundImage: `url(https://xrest-api.onitsoft.com/v1/offers/photo?photo_id=${photo.virgo_id}&basic_watermark=true&additional_watermark=false&photo_size=2000_1333)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    cursor: 'pointer',
                    backgroundPosition: 'center',
                  }}
                />
                <LeftIcon
                  onClick={() => scrollClick(-1)}
                  scrolledBy={index}
                />
                <RightIcon
                  onClick={() => scrollClick(1)}
                  scrolledBy={index}
                />
              </>
            ))}
          </div>
        </Header>
        {window.innerWidth > 1024 && (
          <Agent>
            <AgentCard agentId={offer.agent} />
          </Agent>
        )}
      </HeaderWrapper>
      <Title>
        <h3>{offer.rent ? t('offer.for_rent') : t('offer.for_sale')}</h3>
        <h3>{offer.offer_title}</h3>
      </Title>
      <TitleBar>
        <Price>
          <div>
            <p>
              {' '}
              <SquareIcon />
              {t('offer.area')} <span /> {offer.total_area} m<sup>2</sup>
            </p>
            <p>
              {' '}
              <BedIcon />
              {t('offer.rooms')} <span /> {offer.number_of_rooms}
            </p>
            {options?.includes('Winda') && (
              <p>
                {' '}
                <ElevatorIcon /> {t('offer.elevator')} <span /> {t('offer.yes')}
              </p>
            )}
            {options?.includes('monitoring') && (
              <p>
                <SolidCameraIcon /> {t('offer.cctv')} <span /> {t('offer.yes')}
              </p>
            )}
            {options?.includes('Klimatyzacja') && (
              <p>
                {' '}
                <AcIcon /> {t('offer.ac')} <span /> {t('offer.yes')}
              </p>
            )}
            {options?.includes('internet') && (
              <p>
                {' '}
                <RouterIcon /> Internet <span /> {t('offer.yes')}
              </p>
            )}
          </div>
        </Price>
        <div>
          <h3>
            {parseInt(offer.price).toLocaleString('pl-PL', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}{' '}
            zł
          </h3>
          <h3>
            {Math.ceil(offer.price / offer.total_area)} zł / m<sup>2</sup>
          </h3>
        </div>
      </TitleBar>
      <h2>{t('offer.description_title')}</h2>
      <Content>
        <div
          dangerouslySetInnerHTML={createMarkup(
            i18n.language == 'en' && offer.remarks_description_en != ''
              ? offer.remarks_description_en
              : offer.remarks_description,
          )}
        />
        <span style={{ flex: 1 }} />
      </Content>
      {window.innerWidth <= 1024 && <AgentCard agentId={offer.agent} />}
      <span />
      <h2>{t('offer.localisation')}</h2>
      <MapWrapper>
        <Map
          map_latitude={offer.map_latitude}
          map_longitude={offer.map_longitude}
        />
      </MapWrapper>
      <span />
      <h2>{t('offer.photos')}</h2>
      <Photos>
        {offer.photos.map((photo) => (
          <a
            href={`https://xrest-api.onitsoft.com/v1/offers/photo?photo_id=${photo.virgo_id}&basic_watermark=true&additional_watermark=false&photo_size=2000_1333`}
            target={'_blank'}
          >
            <img
              src={`https://xrest-api.onitsoft.com/v1/offers/photo?photo_id=${photo.virgo_id}&basic_watermark=true&additional_watermark=false&photo_size=2000_1333`}
              alt=''
              onLoad={handleImageLoad}
              style={{ display: imageLoaded ? 'block' : 'none' }}
            />
          </a>
        ))}
      </Photos>
      {isPopActive && (
        <PhotoPop>
          <section />
          <ClosePopIcon onClick={() => setIsPopActive(false)} />
          <img
            src={`https://xrest-api.onitsoft.com/v1/offers/photo?photo_id=${offer.photos[currentImageIndex].virgo_id}&basic_watermark=true&additional_watermark=false&photo_size=2000_1333`}
          />
          <PopLeftIcon
            onClick={() =>
              setCurrentImageIndex((prevIndex) => (prevIndex !== 0 ? prevIndex - 1 : prevIndex))
            }
          />
          <PopRightIcon
            onClick={() =>
              setCurrentImageIndex((prevIndex) =>
                prevIndex !== offer.photos.length - 1 ? prevIndex + 1 : prevIndex,
              )
            }
          />
        </PhotoPop>
      )}
    </Wrapper>
  ) : (
    <LoadingWrapper>
      <SpinnerWrapper>
        <svg
          width='100'
          height='100'
          viewBox='0 0 24 24'
          xmlns='http://www.w3.org/2000/svg'
        >
          <Spinner d='M2,12A11.2,11.2,0,0,1,13,1.05C12.67,1,12.34,1,12,1a11,11,0,0,0,0,22c.34,0,.67,0,1-.05C6,23,2,17.74,2,12Z' />
        </svg>
      </SpinnerWrapper>
    </LoadingWrapper>
  )
}

export default Estate
