import styled, { keyframes } from 'styled-components'

const spinnerAnimation = keyframes`
    100% {
        transform:rotate(360deg)
    }
`

export const Spinner = styled.path`
  transform-origin: center;
  fill: #c0a36a;
  animation: ${spinnerAnimation} 0.6s linear infinite;
`

export const Wrapper = styled.div`
  width: 100%;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`
