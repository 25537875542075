import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import React from 'react'
import './App.css'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import Cookies from 'js-cookie'

import Navbar from './lib/Navbar'
import Home from './components/Home'
import Contact from './components/Contact'
import Search from './components/Search'
import Property from './components/Property'
import Footer from './lib/Footer'
import Credit from './components/Credit'
import RealEstate from './components/RealEstate'
import Estate from './components/EstateDetails'
import Career from './components/Career'

const cookies = Cookies.get('lang')

i18next.use(initReactI18next).init({
  fallbackLng: 'pl',
  lng: cookies,
  ns: ['default'],
  defaultNS: 'default',
  supportedLngs: ['en', 'pl'],
  react: {
    useSuspense: false,
  },
  resources: {
    en: {
      default: require('./assets/locales/en.json'),
    },
    pl: {
      default: require('./assets/locales/pl.json'),
    },
  },
})

export default function App() {
  return (
    <div className='App'>
      <Navbar />
      <Router>
        <Routes>
          <Route
            path=''
            element={<Home />}
          ></Route>
          <Route
            path='/contact'
            element={<Contact />}
          />
          <Route
            path='/contact/search'
            element={<Search />}
          />
          <Route
            path='/contact/property'
            element={<Property />}
          />
          <Route
            path='/contact/credit'
            element={<Credit />}
          />
          <Route
            path='/offer'
            element={<RealEstate />}
          />
          <Route
            path='/offer/*'
            element={<Estate />}
          />
          <Route
            path='/career'
            element={<Career />}
          />
        </Routes>
      </Router>
      <Footer />
    </div>
  )
}
