import styled from 'styled-components'
import img18 from '../../assets/images/img18.jpg'
import img17 from '../../assets/images/img17.jpg'
import img15 from '../../assets/images/img15.jpg'
import img22 from '../../assets/images/img15.jpg'
import { AiOutlineHome } from 'react-icons/ai'
import { BiCoinStack } from 'react-icons/bi'
import { AiOutlineTeam } from 'react-icons/ai'
import { BiCheckDouble } from 'react-icons/bi'
import { BiHomeHeart } from 'react-icons/bi'

export const HomeHeartIcon = styled(BiHomeHeart)`
  width: max-content;
  margin-inline: auto;
  margin-bottom: 20px;
`

export const HomeIcon = styled(AiOutlineHome)`
  font-size: 1rem;
  margin: 0 0.3rem;

  & > path {
    fill: #fff;
  }
`

export const CoinIcon = styled(BiCoinStack)`
  font-size: 1rem;
  margin: 0 0.3rem;

  & > path {
    fill: #fff;
  }
`

export const TeamIcon = styled(AiOutlineTeam)`
  font-size: 1rem;
  margin: 0 0.3rem;

  & > path {
    fill: #fff;
  }
`

export const CheckIcon = styled(BiCheckDouble)`
  font-size: 1rem;
  margin: 0 0.3rem;

  & > path {
    fill: #fff;
  }
`

export const Header = styled.div`
  min-height: 100vh;
  width: 100%;
  background-image: linear-gradient(rgba(4, 9, 30, 0.3), rgba(4, 9, 30, 0.5)), url(${img18});
  background-position: center;
  position: relative;
  background-size: cover;
`

export const TextBox = styled.div`
  width: 90%;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  @media (max-width: 768px) {
    width: 95%;
    top: 30%;
  }
`
export const HeroContent = styled.div`
  margin-bottom: 60px;
  position: absolute;
  max-width: 30rem;
  top: -5rem;

  & h2 {
    color: #fff;
    display: flex;
    margin-bottom: 20px;
    font-size: 2.5rem;
    line-height: 1;
  }

  & p {
    display: flex;
    color: #fff;
    font-size: 0.875rem;
    line-height: 1.8;
    padding-left: 15px;
    border-left: 1px solid;
    margin-bottom: 30px;
  }
`
export const RealEstate = styled.div`
  & a {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 1px solid #c0a36a;
    padding: 12px 34px;
    font-size: 13px;
    background: #c0a36a;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  & a:hover {
    color: #c0a36a;
    border: 1px solid #c0a36a;
    background: transparent;
    cursor: pointer;
  }
`

export const Offer = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 6rem 1rem 1rem 1rem;

  & h1 {
    font-size: 1.5rem;
    font-weight: lighter;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3.5rem;
    color: rgb(29, 38, 54);
  }

  & > div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  & > span {
    z-index: 1;
    position: absolute;
    height: 60%;
    width: 100vw;
    top: 50%;
    right: 0;
    background-color: rgba(15, 15, 15);
  }
`

export const About = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 6rem;

  @media (max-width: 720px) {
    flex-direction: column;
  }
`

export const Container = styled.div`
  padding: 7rem 5rem;
  display: flex;
  width: 100vw;

  @media (max-width: 1024px) {
    padding: 5rem 1rem;
  }
`

export const Photos = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  height: 800px;
  margin: 0 1rem;
  box-shadow: 4px 0px 6px 1px rgba(20, 30, 29, 0.1);
  background-color: #fcfcfc;
  position: relative;
  top: 50px;

  & > div {
    width: 95%;
    height: 95%;
    margin: 2.5% 2.5%;
    background-image: url(${img17});
    background-size: cover;
    background-position: center;
    transform-origin: center center;
  }

  & > span {
    position: absolute;
    background-image: url(${img15});
    box-shadow: 4px 0px 6px 1px rgba(32, 30, 29, 0.2);
    background-size: cover;
    background-position: center;
    transform-origin: center center;
    width: 50%;
    height: 20%;
    top: 60%;
    left: -10%;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`

export const AboutContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 5rem;

  & > h1 {
    text-align: center;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: #c0a36a;
    font-weight: 500;
    padding: 5px 20px;
  }

  & > h2 {
    font-size: 1.7rem;
    margin-bottom: 1rem;
    text-align: center;
    color: #c0a36a;
    font-weight: 500;
    text-transform: uppercase;
  }

  & > p {
    text-align: justify;
    margin-bottom: 1rem;
    color: rgb(69, 69, 69);
    word-spacing: -2px;
    font-size: 1.1rem;
    font-weight: lighter;
    line-height: 1.8rem;
    color: #c0a36a;
  }

  & > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    & > div {
      display: flex;
      flex-direction: column;

      & > div {
        display: flex;

        & > div {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 50px;
          margin-top: 2rem;
          border-radius: 50%;
          background-color: #c0a36a;
          box-shadow: 0px 0px 4px 1px rgba(32, 30, 29, 0.2);
        }

        & > p {
          width: calc(100% - 50px);
          position: relative;
          top: 30px;
          left: 10px;
          color: #c0a36a;
        }
      }
    }
  }

  & > section {
    display: flex;
    background-color: rgba(222, 200, 151, 0.3);
    margin: 2rem 0;

    & > span {
      display: block;
      background-color: #c0a36a;
      height: 100%;
      width: 4px;
    }

    & > p {
      padding: 1.5rem;
      color: #f3f3f3;
      background-color: #c0a36a;
    }
  }

  @media (max-width: 1024px) {
    margin: 2rem 0 0 0;
  }
`

export const LookFor = styled.div`
  z-index: 999;

  & > a {
    text-decoration: none;
    color: #fff;
    border: 1px solid #c0a36a;
    padding: 12px 34px;
    font-size: 13px;
    background: #c0a36a;
    position: relative;
    cursor: pointer;
    width: fit-content;
    transition: all 0.3s ease-in-out;
  }

  & a:hover {
    color: #c0a36a;
    border: 1px solid #c0a36a;
    background: transparent;
    cursor: pointer;
  }
`

export const Cta = styled.section`
  padding: 6rem;

  @media (max-width: 1024px) {
    padding: 3rem;
  }
`

export const Card = styled.div`
  background-color: #c0a36a;
  margin-inline: auto;
  margin-bottom: 30px;
  width: 100%;
  padding: 4rem 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  & a {
    display: inline-block;
    text-decoration: none;
    color: #c0a36a;
    border: 1px solid #fff;
    padding: 12px 60px;
    font-size: 13px;
    background: #fff;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    height: fit-content;
    margin: 2rem;
  }

  & a:hover {
    color: #fff;
    border: 1px solid #fff;
    background: transparent;
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    & {
      flex-direction: column;
    }
  }
`

export const Content = styled.div`
  flex: 1;

  & p,
  h2 {
    color: #fff;
  }

  & h2 {
    line-height: 1.3;
    margin-bottom: 15px;
    font-size: 2.2rem;
    font-weight: 500;
  }

  & p {
    font-size: 1.1rem;
    line-height: 1.7;
  }

  @media (max-width: 1024px) {
    & > h2 {
      font-size: 1.6rem;
      text-align: center;
    }

    & > p {
      text-align: center;
    }
  }
`

export const ServiceCard = styled.div`
  position: relative;
  background: #fff;
  text-align: center;
  padding: 40px 30px;
  box-shadow: var(--shadow-2);
`

export const Con = styled.div`
  background-color: red;

  & ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    text-decoration: none;
  }
`
export const Our = styled.div`
  width: 80%;
  margin: auto;
  text-align: center;
  padding-top: 100px;

  & h1 {
    color: #c0a36a;
    line-height: 70px;
    color: #c0a36a;
    text-transform: uppercase;
    font-weight: 500;
  }

  & > p {
    font-size: 19px;
    line-height: 40px;
    color: rgb(29, 38, 54);
  }
`

export const OurCol = styled.div`
  flex-basis: 31%;
  border-radius: 10px;
  text-align: center;

  & img {
    width: 100%;
    height: 70%;
    object-fit: cover;

    @media (max-width: 1024px) {
      max-height: 300px;
      height: 300px;
    }
  }

  & p {
    padding: 0;
    color: rgb(29, 38, 54);
  }

  & h3 {
    margin-top: 16px;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
    color: #c0a36a;
    text-transform: uppercase;
  }
`
export const Row = styled.div`
  margin-top: 5%;
  justify-content: space-between;
  display: flex;

  @media (max-width: 1024px) {
    flex-direction: column;

    & > * {
      margin-top: 1rem;
    }
  }
`

export const InfoContainer = styled.div`
  min-height: 80vh;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${img22});
  background-position: center;
  position: relative;
  background-size: cover;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    min-height: 60vh;
  }
`
export const TextContainer = styled.div`
  padding: 50px 0 0 0;
  & h2 {
    text-align: left;
    font-size: 2rem;
    color: #fff;
    padding: 50px 20px 50px 20px;
    font-weight: 500;
    background: rgba(0, 0, 0, 0.5);
    margin: 0 560px 0 50px;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      margin: 0 20px;
    }
  }

  & p {
    padding: 0 20px 20px 20px;
    color: #fff;
    font-size: 1.5rem;
    margin: 0 560px 0 50px;
    text-align: left;
    margin-bottom: 1rem;
    word-spacing: -2px;
    font-weight: lighter;
    line-height: 1.8rem;
    background: rgba(0, 0, 0, 0.5);

    @media (max-width: 768px) {
      font-size: 1rem;
      margin: 0 20px;
      line-height: 1.5rem;
    }
  }

  & a {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 1px solid #0f0f0f;
    padding: 12px 34px;
    font-size: 13px;
    background: #0f0f0f;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-align: center;
    margin: 50px;
  }
`
