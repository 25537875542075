import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import MarkerIcon from '../../assets/images/map_marker.svg'
import L from 'leaflet'

const Map = ({ ...props }) => {
  let goldIcon = new L.icon({
    // iconUrl: 'https://cdn.jsdelivr.net/gh/pointhi/leaflet-color-markers@master/img/marker-icon-2x-orange.png',
    iconUrl: MarkerIcon,
    iconSize: [37.5, 61.5],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  })

  return (
    <MapContainer
      center={[props.map_longitude, props.map_latitude]}
      zoom={12}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      <Marker
        position={[props.map_longitude, props.map_latitude]}
        icon={goldIcon}
      />
    </MapContainer>
  )
}

export default Map
