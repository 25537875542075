import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  border: 1px solid #f1f1f1;

  & > img {
    width: 100%;
    display: block;
    object-fit: cover;
    transition: transform 0.2s ease-in-out;
  }

  & > div {
    padding: 1rem;

    & > * {
      display: block;
      width: fit-content;
    }

    & > h1 {
      text-transform: uppercase;
      color: #8b8b8b;
      font-size: 0.9rem;
      font-weight: 300;
    }

    & > h2 {
      color: #8b8b8b;
      font-size: 1.3rem;
      font-weight: 400;
      margin: 1rem 0;
    }

    & > a {
      text-decoration: none;
      margin: 0.4rem 0;
      transition: color 0.5s ease-in-out;

      &:hover {
        color: #c0a36a;
      }
    }
  }
`
