import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { SubHeader2, ContactCol, Row, ContactUs, Location, Text, Text1 } from './careerElements'
import API from '../../api'
import { useForm, Controller } from 'react-hook-form'

const ContactForm = () => {
  const { t } = useTranslation()

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [setShowSuccessMessage] = useState(false)
  const [file, setFile] = useState(null)

  const { control, register, handleSubmit } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
    },
  })

  const onSubmit = async (data) => {
    const formData = new FormData()
    for (const key in data) {
      formData.append(key, data[key])
    }
    if (file) {
      formData.append('file', file)
    }
    formData.append('category', 'career_form')

    try {
      const res = await API.sendMailWithAttachment(formData)
      console.log(res)
      setIsSubmitted(true)
      setShowSuccessMessage(true)
    } catch (error) {
      console.error('Błąd przesyłania: ', error)
    }
  }

  return (
    <motion.form
      onSubmit={handleSubmit(onSubmit)}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
    >
      {isSubmitted && <Text>Twoja wiadomość została wysłana pomyślnie.</Text>}

      <Controller
        control={control}
        name={'name'}
        render={({}) => (
          <input
            type='text'
            name='name'
            placeholder={t('contact.name')}
            {...register('name')}
          />
        )}
      />
      <Controller
        control={control}
        name={'email'}
        render={({}) => (
          <input
            type='email'
            name='email'
            placeholder={t('contact.email')}
            {...register('email')}
          />
        )}
      />
      <Controller
        control={control}
        name={'phone'}
        render={({}) => (
          <input
            type='text'
            name='phone'
            placeholder={t('contact.phone')}
            {...register('phone')}
          />
        )}
      />
      <Controller
        control={control}
        name={'subject'}
        render={({}) => (
          <input
            type='text'
            name='subject'
            placeholder={t('contact.subject')}
            {...register('subject')}
          />
        )}
      />
      <Controller
        control={control}
        name={'message'}
        render={({}) => (
          <textarea
            rows='8'
            name='message'
            placeholder={t('contact.message')}
            {...register('message')}
          />
        )}
      />

      <input
        type='file'
        onChange={(e) => setFile(e.target.files[0])}
      />
      <button type='submit'>{t('contact.send')}</button>
    </motion.form>
  )
}

const Contact = () => {
  const { t } = useTranslation()

  return (
    <>
      <SubHeader2>
        <motion.h1
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1 }}
        >
          {t('career.joinUs')}
        </motion.h1>
      </SubHeader2>

      <Text1>
        <motion.h1
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          {t('career.businessPartner')}
        </motion.h1>
        <hr />
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 1, delay: 1 }}
        >
          {t('career.recruitmentTitle')} <br /> <b>- {t('career.occupation1')} </b> <br />{' '}
          <b>- {t('career.occupation2')}.</b>
          <br />
          {t('career.offerText')}
          <br />
          {t('career.joinUs')}
        </motion.p>
      </Text1>

      <ContactUs>
        <Row>
          <ContactCol>
            <ContactForm />
          </ContactCol>
        </Row>
      </ContactUs>

      <Location>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20485.564230660704!2d19.95150875517889!3d50.073263477419225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47165bb621a118ef%3A0xc3b14b3deee43f06!2sAPREST%20SP.%20Z.O.O!5e0!3m2!1spl!2spl!4v1695658440434!5m2!1spl!2spl'
          width='600'
          height='450'
          style={{ border: 0 }}
          allowFullScreen=''
          loading='lazy'
          referrerPolicy='no-referrer-when-downgrade'
        ></iframe>
      </Location>
    </>
  )
}

export default Contact
