import { useEffect, useReducer } from 'react'
import { t } from 'i18next'
import API from '../../api'
import OfferCard from '../Home/offerCard'
import { useForm, Controller } from 'react-hook-form'
import { Search, SearchContainer, Offers } from './realestateElements'
import { IoChevronBackCircleOutline, IoChevronForwardCircleOutline } from 'react-icons/io5'

const realEstatePageReducer = (state, action) => {
  switch (action.type) {
    case 'goToNextPage':
      window.scrollTo(0, 'smooth')
      return {
        ...state,
        page: state.page + 1,
        isFetching: true,
        searchParameters: {
          ...state.searchParameters,
          page: state.page + 1,
        },
      }
    case 'goToPreviousPage':
      window.scrollTo(0, 'smooth')
      return {
        ...state,
        page: state.page - 1,
        isFetching: true,
        searchParameters: {
          ...state.searchParameters,
          page: state.page - 1,
        },
      }
    case 'goToSpecificPage':
      window.scrollTo(0, 'smooth')
      return {
        ...state,
        page: action.payload,
        isFetching: true,
        searchParameters: {
          ...state.searchParameters,
          page: action.payload,
        },
      }
    case 'resetPage':
      return {
        ...state,
        page: 1,
        isFetching: true,
        offers: [],
      }
    case 'markFetchAsFinished':
      return {
        ...state,
        isFetching: false,
      }
    case 'updateSearchParameters':
      return {
        ...state,
        searchParameters: {
          ...state.searchParameters,
          ...action.payload,
          page: 1,
        },
        isFetching: true,
        page: 1,
        hasMore: true,
      }
    case 'startSearch':
      return {
        ...state,
        isSearched: true,
      }
    case 'endSearch':
      return {
        ...state,
        isSearched: false,
      }
    case 'clearOffers': {
      return {
        ...state,
        offers: [],
        hasMore: true,
        isFetching: false,
      }
    }
    case 'setOffers':
      return {
        ...state,
        count: action.payload.count,
        offers: [...action.payload.data],
        hasMore: action.payload.data.length === state.searchParameters.pageSize,
        isFetching: false,
      }
    case 'setStreets':
      return {
        ...state,
        streets: action.payload,
      }
    default:
      return state
  }
}

const RealEstate = () => {
  const [state, dispatch] = useReducer(realEstatePageReducer, {
    page: 1,
    isFetching: false,
    offers: [],
    hasMore: true,
    streets: [],
    count: 0,
    searchParameters: {
      paginate: true,
      pageSize: 12,
      page: 1,
      areaLow: undefined,
      areaHigh: undefined,
      priceLow: undefined,
      priceHigh: undefined,
      numberOfRooms: undefined,
      street: '',
      offerType: 'sale',
      propertyType: 'Mieszkanie',
      numberOfRoomsLow: undefined,
      numberOfRoomsHigh: undefined,
    },
  })
  const { control, handleSubmit } = useForm({
    defaultValues: state.searchParameters,
  })

  const onSubmit = (data) => {
    const transformedData = {
      ...data,
      priceLow: Number(data.priceLow || 0),
      priceHigh: Number(data.priceHigh || 0),
      areaLow: Number(data.areaLow || 0),
      areaHigh: Number(data.areaHigh || 0),
      numberOfRoomsLow: Number(data.numberOfRoomsLow || 0),
      numberOfRoomsHigh: Number(data.numberOfRoomsHigh || 0),
    }
    console.log('Transformed Data: ', transformedData)
    dispatch({ type: 'clearOffers' })
    dispatch({ type: 'updateSearchParameters', payload: transformedData })
    dispatch({ type: 'startSearch' })
  }

  const searchOffers = () => {
    API.searchOffers(state.searchParameters).then((res) => {
      dispatch({ type: 'setOffers', payload: { data: res.data.response, count: res.data.count } })
    })
  }

  const fetchOffers = () => {
    API.getOffers(12, state.page).then((res) => {
      dispatch({ type: 'setOffers', payload: { data: res.data.response, count: res.data.count } })
    })
  }

  useEffect(() => {
    if (state.isSearched) {
      searchOffers()
    } else {
      fetchOffers()
    }
  }, [state.searchParameters, state.page, state.isSearched])

  useEffect(() => {
    fetchOffers()
    API.getStreets().then((res) => {
      dispatch({ type: 'setStreets', payload: res.data })
    })
  }, [])

  return (
    <>
      <SearchContainer>
        <h2>{t('real_estate.title')}</h2>
        <Search>
          <section>
            <div>
              <label>{t('contact.offerType')}</label>
              <div>
                <Controller
                  control={control}
                  name='offerType'
                  render={({ field }) => (
                    <select {...field}>
                      <option value='sale'>{t('contact.for_sale')}</option>
                      <option value='rent'>{t('contact.for_rent')}</option>
                    </select>
                  )}
                />
              </div>
            </div>
            <div>
              <label>{t('contact.propertyType')}</label>
              <div>
                <Controller
                  control={control}
                  name='propertyType'
                  render={({ field }) => (
                    <select {...field}>
                      <option value='Mieszkanie'>{t('contact.flat')}</option>
                      <option value='Dom'>{t('contact.house')}</option>
                      <option value='Lokal'>{t('contact.commercial')}</option>
                      <option value='Hala'>{t('contact.hall')}</option>
                    </select>
                  )}
                />
              </div>
            </div>
            <div>
              <label>{t('contact.street')}</label>
              <div>
                <Controller
                  control={control}
                  name={'street'}
                  render={({ field }) => (
                    <select {...field}>
                      {state.streets?.map((street) => (
                        <option value={street}>{street}</option>
                      ))}
                    </select>
                  )}
                />
              </div>
            </div>
            <div>
              <label>{t('contact.price')}</label>
              <div>
                <Controller
                  control={control}
                  name='priceLow'
                  render={({ field }) => (
                    <input
                      type='number'
                      {...field}
                      placeholder={'0'}
                    />
                  )}
                />
                <p>-</p>
                <Controller
                  control={control}
                  name='priceHigh'
                  render={({ field }) => (
                    <input
                      type='number'
                      {...field}
                      placeholder={'0'}
                    />
                  )}
                />
              </div>
            </div>
          </section>
          <section>
            <div>
              <label>{t('contact.area')}</label>
              <div>
                <Controller
                  control={control}
                  name='areaLow'
                  render={({ field }) => (
                    <input
                      type='number'
                      {...field}
                      placeholder={'0'}
                    />
                  )}
                />
                <p>-</p>
                <Controller
                  control={control}
                  name='areaHigh'
                  render={({ field }) => (
                    <input
                      type='number'
                      {...field}
                      placeholder={'0'}
                    />
                  )}
                />
              </div>
            </div>
            <div>
              <label>{t('contact.numberOfRooms')}</label>
              <div>
                <Controller
                  control={control}
                  name={'numberOfRoomsLow'}
                  render={({ field }) => (
                    <input
                      type='number'
                      {...field}
                      placeholder={'0'}
                    />
                  )}
                />
                <p>-</p>
                <Controller
                  control={control}
                  name={'numberOfRoomsHigh'}
                  render={({ field }) => (
                    <input
                      type='number'
                      {...field}
                      placeholder={'0'}
                    />
                  )}
                />
              </div>
            </div>
            <div>
              <div>
                <button
                  onClick={() => {
                    dispatch({ type: 'clearOffers' })
                    fetchOffers()
                  }}
                >
                  {t('real_estate.clear')}
                </button>
                <button onClick={handleSubmit(onSubmit)}>{t('real_estate.search')}</button>
              </div>
            </div>
          </section>
        </Search>
      </SearchContainer>
      <Offers>
        <div>
          {state.offers.map((offer) => (
            <OfferCard info={offer} />
          ))}
        </div>
        <section>
          <button
            onClick={() => dispatch({ type: 'goToPreviousPage' })}
            disabled={state.page === 1}
          >
            <IoChevronBackCircleOutline />
          </button>
          {Array.from(
            { length: Math.ceil(state.count / state.searchParameters.pageSize) },
            (_, i) => (
              <button
                key={i + 1}
                onClick={() => dispatch({ type: 'goToSpecificPage', payload: i + 1 })}
                className={`border ${state.page === i + 1 ? 'active' : ''}`}
              >
                {i + 1}
              </button>
            ),
          )}
          <button
            onClick={() => dispatch({ type: 'goToNextPage' })}
            disabled={state.page === Math.ceil(state.count / state.searchParameters.pageSize)}
          >
            <IoChevronForwardCircleOutline />
          </button>
        </section>
      </Offers>
    </>
  )
}

export default RealEstate
