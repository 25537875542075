import styled from 'styled-components'
import img5 from '../../assets/images/img5.jpg'

export const SubHeader2 = styled.div`
  min-height: 50vh;
  width: 100%;
  background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7)), url(${img5});
  background-position: center;
  position: relative;
  background-size: cover;
  text-align: center;
  color: #fff;

  & h1 {
    line-height: 14.5;
    color: #fff;
    font-weight: 300;
  }

  @media (max-width: 768px) {
    position: relative;

    & h1 {
      line-height: normal;
      position: absolute;
      top: 50%;
      width: 100%;
      text-align: center;
    }
  }
`

export const ContactUs = styled.div`
  width: 80%;
  margin: auto;

  @media (max-width: 768px) {
    width: 95%;
  }
`

export const Row = styled.div`
  margin-top: 5%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const ContactCol = styled.div`
  flex-basis: 48%;
  margin-bottom: 30px;

  & input,
  & textarea {
    width: 100%;
    padding: 15px;
    margin-bottom: 17px;
    outline: none;
    border: 1px solid #ccc;
  }

  & button {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 1px solid #c0a36a;
    padding: 12px 34px;
    font-size: 13px;
    background: #c0a36a;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  & button:hover {
    border: 1px solid #c0a36a;
    background: #fff;
    color: #c0a36a;
  }

  @media (max-width: 768px) {
    flex-basis: 100%;
    text-align: center;

    & a {
      width: 75%;
    }
  }
`

export const Coc = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  & input {
    width: 100%;
    padding: 15px;
    margin-bottom: 17px;
    outline: none;
    border: 1px solid #ccc;
  }

  & p {
    padding: 0;
  }

  & h5 {
    font-size: 20px;
    margin-bottom: 5px;
    color: #555;
    font-weight: 400;
  }

  & a {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 1px solid #c0a36a;
    padding: 12px 34px;
    font-size: 13px;
    background: #c0a36a;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  & a:hover {
    border: 1px solid #c0a36a;
    background: #fff;
    color: #c0a36a;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`

export const Location = styled.div`
  width: 100vw;
  margin: auto;
  padding: 80px 0;

  & iframe {
    width: 100%;
    height: 450px;
  }

  @media (max-width: 768px) {
    padding: 40px 0;
    & iframe {
      height: 300px;
    }
  }
`

export const Coc1 = styled.div`
  & a {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 1px solid #c0a36a;
    padding: 12px 34px;
    font-size: 13px;
    background: #c0a36a;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    min-width: 187px;
    text-align: center;
  }

  & a:hover {
    border: 1px solid #c0a36a;
    background: #fff;
    color: #c0a36a;
  }

  @media (max-width: 768px) {
    & a {
      padding: 10px 20px;
      font-size: 11px;
    }
  }
`

export const Team = styled.section`
  & > h1 {
    font-size: 1.3rem;
    color: rgb(29, 38, 54);
    text-align: center;
    margin-bottom: 3rem;
    padding-top: 4rem;
  }

  & > section {
    margin: 0 auto;
    width: 90%;
    display: grid;
    row-gap: 1rem;
    column-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(333px, 1fr));

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
`

export const Text = styled.p`
  background-color: rgba(13, 157, 75, 0.94);
  color: white;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 14px;
  }
`

export const Text1 = styled.div`
  width: 80%;
  margin: auto;
  text-align: center;
  padding: 85px;

  & h1 {
    color: rgb(69, 69, 69);
    font-size: 36px;
    font-weight: 300;
    line-height: 65px;
  }

  & p {
    color: #777;
    font-weight: 300;
    line-height: 22px;
    padding: 10px;
    font-size: 20px;
    text-align: center;
    line-height: 2rem;
  }

  @media (max-width: 768px) {
    padding: 15px;
    & h1 {
      font-size: 24px;
      line-height: 1.5;
    }

    & p {
      font-size: 16px;
      line-height: 1.4;
    }
  }
`
