import styled from 'styled-components'
import img14 from '../../assets/images/img14.jpg'

export const SubHeader2 = styled.div`
  min-height: 50vh;
  width: 100%;
  background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7)), url(${img14});
  background-position: center;
  position: relative;
  background-size: cover;
  text-align: center;
  color: #fff;

  & h1 {
    line-height: 14.5;
    color: #fff;
    font-weight: 300;
  }

  @media (max-width: 768px) {
    position: relative;

    & h1 {
      line-height: normal;
      position: absolute;
      top: 50%;
      width: 100%;
      text-align: center;
    }
  }
`
export const ContactUs = styled.div`
  width: 80%;
  margin: auto;
`

export const Row = styled.div`
  margin-top: 5%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`

export const ContactCol = styled.div`
  flex-basis: 48%;
  margin-bottom: 30px;

  & input {
    width: 100%;
    padding: 15px;
    margin-bottom: 17px;
    outline: none;
    border: 1px solid #ccc;
    /* position: relative;
	right: -600px;
    top: -100px; */
  }

  & textarea {
    width: 100%;
    padding: 15px;
    margin-bottom: 17px;
    outline: none;
    border: 1px solid #ccc;
    /* position: relative;
    right: -600px;
    top: -100px; */
  }

  & button {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 1px solid #c0a36a;
    padding: 12px 34px;
    font-size: 13px;
    background: #c0a36a;
    position: relative;
    cursor: pointer;
    /* right: -600px;
    top: -100px; */
    transition: all 0.3s ease-in-out;
  }

  & button:hover {
    border: 1px solid #c0a36a;
    background: #fff;
    cursor: pointer;
    color: #c0a36a;
  }
`

export const Coc = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  & input {
    width: 100%;
    padding: 15px;
    margin-bottom: 17px;
    outline: none;
    border: 1px solid #ccc;
  }

  & p {
    padding: 0;
  }

  & h5 {
    font-size: 20px;
    margin-bottom: 5px;
    color: #555;
    font-weight: 400;
  }

  & a {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 1px solid #c0a36a;
    padding: 12px 34px;
    font-size: 13px;
    background: #c0a36a;
    position: relative;
    cursor: pointer;
    /* right: -600px;
        top: -100px; */
    transition: all 0.3s ease-in-out;
    min-width: 187px;
    text-align: center;
  }

  & a:hover {
    border: 1px solid #c0a36a;
    background: #fff;
    cursor: pointer;
    color: #c0a36a;
  }
`

export const Location = styled.div`
  width: 100vw;
  margin: auto;
  padding: 80px 0;

  & iframe {
    width: 100%;
    height: 450px;
  }
`

export const TextForm = styled.p`
  background-color: rgba(13, 157, 75, 0.94);
  color: white;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
`

export const Text1 = styled.div`
  width: 80%;
  margin: auto;
  text-align: center;
  padding: 85px;

  & h1 {
    color: rgb(69, 69, 69);
    font-size: 36px;
    font-weight: 300;
    line-height: 65px;
  }

  & p {
    color: #777;
    font-weight: 300;
    line-height: 22px;
    padding: 10px;
    font-size: 20px;
    text-align: center;
    line-height: 2rem;
  }

  @media (max-width: 768px) {
    padding: 15px;
    & h1 {
      font-size: 24px;
      line-height: 1.5;
    }

    & p {
      font-size: 16px;
      line-height: 1.4;
    }
  }
`
