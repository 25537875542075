import styled from 'styled-components'
import team2 from '../../assets/images/team2.jpg'

export const SubHeader2 = styled.div`
  min-height: 60vh;
  width: 100%;
  background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.4)), url(${team2});
  background-position: center;
  position: relative;
  background-size: cover;
  text-align: center;
  color: #fff;

  & h1 {
    line-height: 14.5;
    color: #fff;
    font-weight: 300;
  }
`

export const ContactUs = styled.div`
  width: 80%;
  margin: auto;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ContactCol = styled.div`
  flex-basis: 48%;
  margin: 0 auto 30px auto;

  & input {
    width: 100%;
    padding: 15px;
    margin-bottom: 17px;
    outline: none;
    border: 1px solid #ccc;
  }

  & textarea {
    width: 100%;
    padding: 15px;
    margin-bottom: 17px;
    outline: none;
    border: 1px solid #ccc;
  }

  & button {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 1px solid #c0a36a;
    padding: 12px 34px;
    font-size: 13px;
    background: #c0a36a;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  & button:hover {
    border: 1px solid #c0a36a;
    background: #fff;
    cursor: pointer;
    color: #c0a36a;
  }
`

export const Coc = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  & input {
    width: 100%;
    padding: 15px;
    margin-bottom: 17px;
    outline: none;
    border: 1px solid #ccc;
  }

  & p {
    padding: 0;
  }

  & h5 {
    font-size: 20px;
    margin-bottom: 5px;
    color: #555;
    font-weight: 400;
  }

  & a {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 1px solid #c0a36a;
    padding: 12px 34px;
    font-size: 13px;
    background: #c0a36a;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  & a:hover {
    border: 1px solid #c0a36a;
    background: #fff;
    cursor: pointer;
    color: #c0a36a;
  }
`

export const Location = styled.div`
  width: 100vw;
  margin: auto;
  padding: 80px 0;

  & iframe {
    width: 100%;
    height: 450px;
  }
`

export const Coc1 = styled.div`
  & a {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 1px solid #c0a36a;
    padding: 12px 34px;
    font-size: 13px;
    background: #c0a36a;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    min-width: 187px;
    text-align: center;
  }

  & a:hover {
    border: 1px solid #c0a36a;
    background: #fff;
    cursor: pointer;
    color: #c0a36a;
  }
`

export const Text = styled.p`
  background-color: rgba(13, 157, 75, 0.94);
  color: white;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
`
export const Text1 = styled.div`
  width: 80%;
  margin: auto;
  text-align: center;
  padding: 85px;

  & h1 {
    color: rgb(69, 69, 69);
    font-size: 36px;
    font-weight: 300;
    line-height: 65px;
  }

  & p {
    color: #777;
    font-weight: 300;
    line-height: 22px;
    padding: 10px;
    font-size: 20px;
    text-align: center;
    line-height: 2rem;
  }

  & hr {
  }
`
